exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-project-tsx-content-file-path-src-projects-botrista-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/cdiorio/portfolio/CarterDiOrio.github.io/src/projects/botrista.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-src-projects-botrista-mdx" */),
  "component---src-templates-project-tsx-content-file-path-src-projects-camera-calibration-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/cdiorio/portfolio/CarterDiOrio.github.io/src/projects/camera-calibration.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-src-projects-camera-calibration-mdx" */),
  "component---src-templates-project-tsx-content-file-path-src-projects-dynamics-simulation-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/cdiorio/portfolio/CarterDiOrio.github.io/src/projects/dynamics-simulation.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-src-projects-dynamics-simulation-mdx" */),
  "component---src-templates-project-tsx-content-file-path-src-projects-structure-from-motion-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/cdiorio/portfolio/CarterDiOrio.github.io/src/projects/structure-from-motion.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-src-projects-structure-from-motion-mdx" */),
  "component---src-templates-project-tsx-content-file-path-src-projects-trajectory-mdx": () => import("./../../../src/templates/project.tsx?__contentFilePath=/home/cdiorio/portfolio/CarterDiOrio.github.io/src/projects/trajectory.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-src-projects-trajectory-mdx" */)
}

